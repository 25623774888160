import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
	root: {
		position: 'fixed',
		bottom: 0,
		left: '40%',
		right: '40%',
		padding: '8px',
		backgroundColor: 'rgba(0, 0, 0, 0.7)',
		// boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.7)',
		borderRadius: '10px 10px 0px 0px',
		color: 'white',
		textAlign: 'center',
		zIndex: 1300 // To ensure it stays above other content
	},
	text: {
		fontSize: '0.875rem',
		color: 'white'
	}
}));

function BottomBar() {
	const classes = useStyles();
	const environment = process.env.REACT_APP_ENV || 'production';

	// Only show for non-production environments
	if (environment === 'production') return null;

	return environment === 'production' ? null : (
		<div className={classes.root}>
			<Typography className={classes.text}>
				You are in {environment.charAt(0).toUpperCase() + environment.slice(1)} environment
			</Typography>
		</div>
	);
}

export default BottomBar;
