import React, { Suspense, useState } from 'react';
import { renderRoutes } from 'react-router-config';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { LinearProgress, CircularProgress } from '@material-ui/core';
import Authentication from 'src/utils/Authentication';
import Error404 from 'src/customComponents/Error404';
import NavBar from './NavBar';
import TopBar from './TopBar';
import { getLoader, getAuth } from '../../selectors';
import BottomBar from 'src/components/BottomBar/BottomBar';

const useStyles = makeStyles((theme) => ({
	container: {
		minHeight: '100vh',
		display: 'flex',
		'@media all and (-ms-high-contrast:none)': {
			height: 0 // IE11 fix
		}
	},
	content: {
		paddingTop: 64,
		flexGrow: 1,
		maxWidth: '100%',
		overflowX: 'hidden',
		[theme.breakpoints.up('lg')]: {
			paddingLeft: 192
		},
		[theme.breakpoints.down('xs')]: {
			paddingTop: 56
		}
	},
	loaderContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'fixed',
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
		zIndex: 9999,
		backgroundColor: '#000',
		opacity: 0.7
	}
}));

function Dashboard({ route }) {
	const classes = useStyles();
	const history = useHistory();
	const [openNavBarMobile, setOpenNavBarMobile] = useState(false);
	const isLoading = useSelector(getLoader);
	const auth = useSelector(getAuth);

	if (!Authentication.isAuthenticated) {
		history.push('/admin/auth/login');
		return null;
	}

	return (
		<>
			<TopBar onOpenNavBarMobile={() => setOpenNavBarMobile(true)} />
			<NavBar
				onMobileClose={() => setOpenNavBarMobile(false)}
				openMobile={openNavBarMobile}
			/>
			<div className={classes.container}>
				<div className={classes.content}>
					{isLoading && (
						<div className={classes.loaderContainer}>
							<CircularProgress />
						</div>
					)}
					<Suspense fallback={<LinearProgress />}>
						{auth?.projects?.length === 0 && <Error404 />}
						{auth?.projects?.length !== 0 && renderRoutes(route.routes)}
					</Suspense>
				</div>
			</div>
			<BottomBar />
		</>
	);
}

Dashboard.propTypes = {
	route: PropTypes.object
};

export default Dashboard;
